module.exports = {
    "防骗指南": "Anti-Scam Guide",
    "本平台所有资源均为实名制，小姐姐与小哥哥皆是VIP会员请对双方需一定的尊重，请不要出言不逊没有素质，经发现将取消约炮资格请谨记！，重要的事情说三遍！！！": "All members on this platform are real-name and verified. Both sister and brother are VIP members, please respect each others, please don't speak rudely and have no quality, you will be disqualified from dating, please pay attention!", 
    "小姐姐是不会收取任何费用，只需完成平台三次打赏任务获得约炮名额即可享受同城约炮资源，开房花销需自理哦！": "Girls will not charge any fees. You only need to complete three rewarding tasks on the platform to get a dating appointment to enjoy the same-city dating resources. Room opening expenses by yourself!",
    "妹子已到楼下,要求付【全款才能上楼】均为骗子无疑，请立即来平台联系接待客服举报！": "The girl has arrived and asked to pay [full payment to pick up] are undoubtedly liars, please proceed to the platform immediately to contact the reception customer service to report!",
    "友情提醒：老师，接待员，派单员，都不会私加客户的联系方式，上班时间均为 13.00~01.00 其余时间均不办理业务，若有在下班时间他人给你发送信息请不要相信避免资金受损我方概不负责！！！": "Kindly reminder: managers , receptionists, and taskers will not add customer contact information privately. The working hours are 10.00~21.00. The rest of the time will not handle business. If someone sends you a message during off-duty time, please do not believe it and avoid it. We are not responsible for any loss of funds! ! !",
    "凡是要求下载其他软件的都是骗子,请立即截图来平台联系接待客服举报！": "Anyone who asks to download other software is a liar, please take a screenshot immediately to the platform to contact the reception customer service to report!",
    "本平台对每一位VIP会员寻欢经历负责,遇到任何问题请及时来平台联系接待客服举报,官方核实后退还解锁消耗!": "This platform is responsible for the pleasure-seeking experience of every VIP member. If you encounter any problems, please come to the platform to contact the reception customer service to report in time. After the verification, the consumption will be refunded!",
    "预约大厅": "Reservation Lobby",
    "会员视频": "Member Video",
    "骑在身上大屌套弄": "Riding Big Cock",
    "选妃中心": "Girl Selection Center",
    "新加入": "Newly Joined",
    "附近的人": "People nearby",
    "为您发现同城炮友 999+ 位": "Discover 999+ friends same city",
    "刷新成功": "Refreshed",
    "为您发现同城炮友": "Find friends nearby your city",
    "本平台所有资源真实、安全、可靠、全国空降 选取您心仪的她 随时随地 开启约啪~": "All resources on this platform are real, safe, reliable, and nationwide. Choose your favorite girl and start dating anytime, anywhere~",
    "联系专属接待客服": "Contact Customer Service",
    "开卡可见": "Get VIP to discover",
    "寶衡传媒打分": "Hook Up Media Score",
    "美女颜值": "Beauty Value",
    "详细地址：已隐藏 联系小蜜开卡可见": "Detailed address: Hidden, Contact customer service to get VIP Privilege",
    "联系方式：已隐藏 联系小蜜开卡可见": "Contact information: Hidden, Contact customer service to get VIP Privilege",
    "基本信息 20岁,身高 175": "Basic Information：{Age}Age, Height {Height}",
    "所在地区": "Location：",
    "服务项目 激活可见": "Service：Activate To View",
    "祝会员们，天天约得美娇娘，夜夜都能当新郎！": " Wish all the members, can date their dream girl, and have a nice sex every night!",
    "立即约她": "Date her now",
    "请联系专属客服接待": "Contact Customer Service",
    "寶衡传媒": "HOOK UP",
    "华人第一福利品牌": "The Top Welfare Brand",
    "为了会员权益，网站只展示": "For the benefit of members, the website will only displays",
    "资源并且不定期更新只为展示实力，如需查询预约更多资源请联系上级接待": "The resources are always updated on time. Contact customer services for more information.",
    "本平台所有资源真实丶安全丶可靠，全国空降丶同城约炮选取你心仪的她，与您零距离邂逅，快加入我们吧": "All resources on this platform are real, safe, and reliable. Door-to-door service all over the country, same-city dating, choose your favorite girl, encounter you at zero distance. Come join us",
    "寶衡资源": "HOOK UP",
    "昵称": "Nickname",
    "类型": "Type",
    "服务项目: 激活可见": "Services : Activate To View",
    "已认证": "Verified",
    "实时配对": "CP Now",
    "约炮心得": "Comment",
    "服务条款": "T&C",
    "本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。": "This platform is the most authentic escort + business companion + city passion in the network. In order to ensure the personal privacy of each user, customers can only join by contacting the receptionist or the real-name recommendation of senior members of the platform",
    "有哪些资源?": "What resources are there?",
    "网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到": "Influencers, celebrities, models, stewardess, young models, students, there are only things you can't think of, and there is nothing can't do in this platform",
    "服务范围": "Service Area",
    "服务项目：做爱3次 可无套内射 深喉口交 漫游  毒龙 按摩": "Services: 3 times sex, BBFS，BBBJ , Deepthroat, Rim job, Massage",
    "可拍照留念： 私处特写 做爱录屏 口交录屏": "Can take pictures : Close-up of private parts, sex recording, blowjob recording",
    "可指定制服：护士-女仆-空姐-秘书-女警-猫女": "Uniforms can be specified : Nurse - Maid - Stewardess - Secretary - Policewoman - Catwoman",
    "可捆绑轻虐：配合使用各式男女情趣性爱道具": "Can be bondage and discipline lightly abused: use all kinds of sex toys and tools for men and women together",
    "北京": "Kuala Lumpur",
    "上海": "Selangor",
    "广州": "Putrajaya",
    "深圳": "Sarawak",
    "南京": "Kedah",
    "长沙": "Perlis",
    "重庆": "Penang",
    "西安": "Johor",
    "丁先生": "Mr. Ding",
    "通过本站已预约......": "Appointment has been made through this website...",
    "朱先生": "Mr. Zhu",
    "正在服务中......": "In service...",
    "李先生": "Mr. Lee",
    "郭先生": "Mr. Guo",
    "路先生": "Mr. Sim",
    "郝先生": "Mr. Adrian",
    "赵先生": "Mr. Lim",
    "祝先生": "Mr. Jeffrey",
    "牛先生": "Mr. Daniel",
    "张先生": "Mr. Ong",
    "联系约好时间叫我去她家操她，皮肤还是很紧致": "Contacted and made an appointment to ask me to go to her house to fuck her, the skin is so soft",
    "颜值很高，服务很专业，还会热舞...": "Is very beautiful, the service is very professional, and there are hot dances...",
    "床上满分 可一字马 性格超好 氵超多": "Perfect score in bed, super good personality, squirt too much",
    "朋友介绍的，妞可以年轻漂亮，服务够莞式。": "Introduced by a friend, the girl is young and beautiful, and the service is good",
    "今天正好有空闲，正好去验证一下，就约了一下。见面一看，胸不大不小，身材很好，和照片一致。": "I was free today, so I just went to it, I made an appointment. When we met, I found that the boobs was so big and bouncy, and the body was very good, which was same in the photo",
    "进门一看，轻熟女，我喜欢少妇，穿上高跟踩上更 突显身材。简单洗洗开工，口的很舒服，姐口的包裹感十足": "When I entered the door, I saw a mature women. I like mature women. Wearing high heels with perfect slim body and walking to me. I took a shower and start work, the blowjob was very comfortable, and the girl's mouth is sucking so perfectly tight",
    "很是用心，人也比较魅，比较骚，说话温柔，骚话说起来一套一套，让人兴奋度比较高，能激起男性的性欲": "Very attentive, the girl are charming, and horny, soft-spoken, flirting me with words, making me so excited, and I am on flamed...",
    "妹子看上去很小，服务一般，身子有点胖态度还是可以的 喜欢嫩的可以去试试...": "The girl looks petite , the service is normal, she is a bit shy but her attitude is ok. If you like young girl, you can try it...",
    "妹子长相甜美，一颗美人痣最为诱惑身材不错，喜欢旅游，性格温柔开朗": "The girl looks sweet, and attractive with a mole on the cheek. She has a good figure, likes to travel, and has a gentle and cheerful personality",
    "妹子比较嫩，颜值比较高，就是皮肤有点黑。妹子最近才回上海，简单服务陪浴口做。": "The girl is relatively  young and looks so beautiful, but her skin is a bit dark. The girl just returned to from Sabah recently, and I cum in her mouth",
    "登录": "Login",
    "请输入用户名": "Please enter username",
    "请输入密码": "Please enter password",
    "忘记密码？": "Forgot password?",
    "没有账户？马上注册": "Don't have an account? Register now",
    "注册": "Register",
    "请输入用户名(6-12个英文字母或数字)": "Please enter username (6-12 letters or numbers)",
    "请输入登录密码(6-12个英文字母或数字)": "Please enter login password (6-12 letters or numbers)",
    "我已经知晓并同意'开户协议'各项条约": "I have read and agreed to the 'Account Registration Agreement'",
    "请输入邀请码": "Please enter invitation code",
    "请勾选下方开户协议": "Please check the 'Account Registration Agreement' below",
    "预约做单": "Appointment",
    "距 第": "Next round",
    "期 截止": "Time left",
    "第": "Round",
    "期": "",
    "糖心": " Desire Love",
    "烟花": "Shooting Stars",
    "双份": "Double Gift",
    "单份": "Single Gift",
    "双": "Rocket Launch",
    "单": "Romantic Rose",
    "本次数据作为直播间打赏主播人气置顶主播排行榜,正式客户完成后可免费开直播间权限": "This statistic will be used to rank the popularity board of VJ in the live broadcast room. Official members can open live broadcast room access for free after completion",
    "与主播私下约啪": "Arrange a private meeting with the VJ ",
    "任务单": "Task list",
    "可用余额": "Remaining balance",
    "元": "Points",
    "打赏": "Send Gift",
    "当前选号": "Current selection",
    "打赏金额": "Gift amount",
    "请输入金额": "Please enter amount",
    "总共打赏": "Total Gifting",
    "合计": "Total",
    "清空订单": "Clear order",
    "确认打赏": "Confirm gift",
    "期号": "Round number",
    "正确打赏": "Correct gift",
    "约炮数据一": "Flirt partner one",
    "约炮数据二": "Flirt partner two",
    "约炮数据三": "Flirt partner three",
    "高级VIP数据": "VVIP partner",
    "填写收款卡": "Fill in payment card",
    "请输入您的收款卡信息": "Please enter your payment card information",
    "请输入真实银行卡号": "Please enter your bank card number",
    "请选择银行": "Please select a bank",
    "银行卡号": "Bank card number",
    "银行名称": "Bank name",
    "尊敬的用户,为了保障您的资金安全,请您确认您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款": "Dear user, in order to ensure the safety of your funds, please confirm your real name and set a withdrawal password. If your name is not consistent with the account name, you will not be able to withdraw funds",
    "确认提现": "Confirm withdrawal",
    "任务记录": "Task record",
    "数据为空": "Data is empty",
    "打赏积分": "Gift points",
    "下单时间": "Order time",
    "结算时间": "Settlement time",
    "充值": "Deposit",
    "提现": "Withdrawal",
    "我的钱包": "My wallet",
    "详情": "Details",
    "余额(元)": "Balance ($MYR)",
    "个人报表": "Personal report",
    "账户明细": "Account details",
    "打赏记录": "Gift record",
    "个人中心": "Personal center",
    "信息公告": "Announcement",
    "在线客服": "Online customer service",
    "请完成任务单后进入": "Please enter after completing the task list",
    "请联系客服充值": "Please contact customer service for recharge",
    "请设置收款卡": "Please set up a payment card",
    "功能已禁用": "Function is disabled",
    "登录/注册": "Log in/Register",
    "登录可享受更多服务": "Login for more services",
    "基本信息": "Basic Information",
    "头像": "Profile Picture",
    "选择头像": "Select Profile Picture",
    "确定": "Confirm",
    "真实姓名": "Real Name",
    "未设置": "Not Set",
    "性别": "Gender",
    "男": "Male",
    "女": "Female",
    "未知": "Unknown",
    "绑定信息": "Binding Information",
    "已绑定": "Binded",
    "无": "None",
    "系统公告": "System Announcement",
    "盈利金额(元)": "Profit Amount (MYR)",
    "盈利计算公式 : 盈利金额 - 任务金额": "Profit Calculation: Profit Amount - Task Amount",
    "任务金额": "Task Amount",
    "充值金额": "Top-Up Amount",
    "提现金额": "Withdrawal Amount",
    "中奖金额": "Winning Amount",
    "蜜獾娱乐": "MiHuan Entertainment",
    "联系": "Contact",
    "全天7 * 24小时竭诚为您服务": "We are available 24/7 to serve you",
    "收款卡信息": "Beneficiary Bank Information",
    "添加收款卡": "Add Beneficiary Bank",
    "提示:请设置大型商业银行,如需修改,请您联系在线客服": "Tip: Please set up a well known bank. If you need to modify it, please contact our online customer service",
    "请设置姓名后再绑定银行卡": "Please set up your name before binding your bank card",
    "请设置提现密码后再绑定银行卡": "Please set up your withdrawal password before binding your bank card",
    "修改登录密码": "Change Login Password",
    "保存": "Save",
    "旧密码": "Old Password",
    "请输入您的旧密码": "Please enter your old password",
    "新密码": "New Password",
    "请输入您的新密码": "Please enter your new password",
    "请填写完整": "Please fill in completely",
    "两次密码输入不一致": "The two passwords entered do not match",
    "修改真实姓名": "Change Real Name",
    "姓名": "Name",
    "请输入真实姓名": "Please enter your real name",
    "为了您账户安全,真实姓名需要与绑定银行卡姓名一致": "To ensure the security of your account, your real name should match the name on the linked bank card",
    "请勿重复设置": "Please avoid duplicate settings",
    "请输入姓名": "Please enter your name",
    "设置资金密码": "Set withdraw password",
    "请输入资金密码": "Please enter the withdraw password",
    "请再次输入资金密码": "Please re-enter the fund password",
    "两次密码不一致": "The passwords do not match",
    "性别修改": "Modify gender",
    "设置": "Set",
    "基本信息设置": "Basic information settings",
    "登录密码": "Login password",
    "资金密码": "Withdraw password",
    "退出登录": "Logout",
    "提现密码已设置，需要修改请联系客服": "The withdrawal password has been set. Please contact customer service for any modifications",
    "已设置": "Already set",
    "提现中心": "Withdrawal center",
    "提现记录": "Records",
    "提现金额 (元)": "Withdrawal amount (MYR)",
    "到账时间：一般到账时间在5分钟左右，最快2分钟内到账": "Billing time : Generally, it takes about 5 minutes for the withdrawal to be processed and it can be done within 2 minutes",
    "说明": "Information",
    "余额": "Balance",
    "马上提现": "Withdraw now",
    "请填写正确的金额": "Please enter the correct amount",
    "金额": "Amount",
    "提交时间": "Submit time",
    "审核时间": "Review time",
    "没有更多了": "No more",
    "播放": "Played",
    "次播放": "Views",
    "热门推荐": "For You",
    "账号下线": "Account offline",
    "请充值后观看视频": "Please recharge before watching videos",
    "直播间": "Live room",
    "官方认证女神": "Official Verified Girl",
    "已缴纳20000保证金": "Has paid a 20,000 deposit",
    "地区": "Region",
    "签名": "Signature",
    "获取联系方式": "Get Contact",
    "温馨提示": "Kindly reminder",
    "联系数据老师激活权限": "Contact the manager to activate permissions",
    "取消": "Cancel",
    "标题": "Title",
    "服务项目：做爱3次 可无套内射 深喉口交 漫游 毒龙 按摩": "Services: 3 times sex, BBFS，BBBJ , Deepthroat, Rim job, Massage",
    "首页": "Home",
    "预约": "Appointment",
    "我的": "Me",
    "注": "Note",
    "已结算": "Settled",
    "结算中": "Settlement",
    "待结算": "Settlement",
    "金额错误": "Incorrect amount",
    "请选号": "Please select a partner",
    "请填写金额": "Please enter the amount",
    "余额不足，请联系客服充值": "Insufficient balance, Please recharge",
    "请联系管理员领取该任务": "Contact admin to get task",
    "开奖成功，期号": "Lottery Successful, Round Number:",
    "玫瑰": "Romantic Rose",
    "火箭": "Rocket Launch",
    "添加转数快": "Add TNG (Faster Payment System)",
    "填写转数快": "Fill in FPS",
    "请输入您的转数快信息": "Please enter your FPS information",
    "联系电话": "Contact phone number",
    "根据银联风控要求同卡24小时内验证次数不能超过4次，感谢配合": "According to MASTER/VISA risk control requirements, the verification frequency for the same card within 24 hours cannot exceed 4 times. Thank you for your kindly cooperation",
    "确认": "Confirm",
    "已存在提款方式，请勿重复绑定": "Withdrawal method already exists, please do not bind it again",
    "公告": "Announcement",
    "東區": "Puchong Area",
    "后生女": "Young girl",
    "通過本站已預約......": "Booked through this site......",
    "正在服務中......": "In Service...",
    "中西區": "Subang Area",
    "御姐": "Elder sister",
    "灣仔區": "Johor",
    "火辣": "Hot",
    "南區": "Penang",
    "油尖旺區": "Kuchai lama",
    "人妻": "wife",
    "深水埗區": "Sri Petaling",
    "少婦": "Housewife",
    "離島區": "Kuching",
    "元朗區": "Ipoh",
    "葵青區": "Negeri Sembilan",
    "沙田區": "Miri",
    "西貢區": "Kedah",
    "北區": "Melaka",
    "九龍城區": "Cheras",
    "觀塘區": "Kuantan",
    "荃灣區": "Seremban",
    "屯門區": "Petaling Jaya",
    "大埔區": "Kajang",
    "顏值很高，服務很專業，還會熱舞...": "Is very beautiful, the service is very professional, and there are hot dances...",
    "朋友介紹的，妞可以年輕漂亮，服務夠莞式。": "Introduced by a friend, the girl is young and beautiful, and the service is good",
    "進門一看，輕熟女，我喜歡少婦，穿上高跟踩上更 突顯身材。簡單洗洗開工，口的很舒服，姐口的包裹感十足": "When I entered the door, I saw a mature women. I like mature women. Wearing high heels with perfect slim body and walking to me. I took a shower and start work, the blowjob was very comfortable, and the girl's mouth is sucking so perfectly tight",
    "床上滿分 可一字馬 性格超好 氵超多": "Perfect score in bed, super good personality, squirt too much",
    "很是用心，人也比較魅，比較騷，說話溫柔，騷話說起來一套一套，讓人興奮度比較高，能激起男性的性慾": "Very attentive, the girl are charming, and horny, soft-spoken, flirting me with words, making me so excited, and I am on flamed...",
    "聯繫約好時間叫我去她家操她，皮膚還是很緊緻": "Contacted and made an appointment to ask me to go to her house to fuck her, the skin is so soft",
    "未选择":"not selected",
    "请输入联系电话":"Please type your phone number",
}